import { DATAHUB_SEARCH } from "env"
import { appendLocale, fetchLocationCoordinates, getLocationType } from "utils"
import {
  getArchiveFilters,
  getGeoLocation,
  getJobsFilters,
} from "utils/jobFetchingUtils"

export async function fetchJobs(key, params, pageIndex, locale) {
  const {
    search,
    location,
    radius,
    wage,
    start_date,
    job_type,
    job_status,
    facets,
    sort,
  } = params

  try {
    const PAGE_SIZE = 10
    let FILTER = []
    let ORDER_BY = []
    let SKIP = pageIndex * PAGE_SIZE
    let TOP = PAGE_SIZE
    let SELECT = `active,case_number,job_title${appendLocale(
      locale
    )},begin_date,end_date,basic_rate_from,basic_rate_to,pay_range_desc,employer_trade_name,employer_business_name,worksite_city,worksite_state`

    const isJobs = key === "jobs"
    const isArchive = key === "archive"
    const jobTypeAll = job_type && job_type !== "all"

    const { type, search: modifiedLocation } = getLocationType(location)

    if (isJobs) {
      getJobsFilters({ FILTER, facets, locale, wage })
    }

    if (isArchive) {
      getArchiveFilters({ FILTER, job_status })
    }

    // START DATE FILTER
    if (start_date) {
      let d = new Date(start_date).toISOString()
      let noTimezoneDate = d.split("T")[0] + "T00:00:00.000Z"
      FILTER.push(`begin_date ge ${noTimezoneDate}`)
    }

    // JOB TYPE FILTER: all, H-2A, H-2B
    if (jobTypeAll) {
      FILTER.push(`search.in(visa_class, '${job_type.toString()}')`)
    }

    if (location && !isJobs && type !== "just_coords") {
      FILTER.push(`worksite_${type} eq '${modifiedLocation}'`)
    }
    // COORDINATE/RADIUS SEARCH: for '/jobs' only
    if (location && isJobs) {
      const location_coords = await fetchLocationCoordinates(location)
      if (location_coords) {
        const { lat, lon } = location_coords
        FILTER.push(
          getGeoLocation({ lat, lon, radius, type, modifiedLocation })
        )
        ORDER_BY.push(
          `geo.distance(coord, geography'POINT(${lon} ${lat})') asc`
        )
      }
    }

    // SORT ASSEMBLY
    switch (sort) {
      case "relevancy":
        ORDER_BY.push("search.score() desc")
        break
      case "begin_date":
        ORDER_BY.push("begin_date asc")
        break
      case "basic_rate_from":
        ORDER_BY.push("basic_rate_from desc")
        break
      case "accepted_date":
        ORDER_BY.push("accepted_date desc")
        break
      default:
        ORDER_BY.push("search.score() desc")
    }

    const res = await fetch(DATAHUB_SEARCH, {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "User-Agent": "*",
      },
      body: JSON.stringify({
        search,
        searchFields: `job_title${appendLocale(
          locale
        )}, job_duties${appendLocale(
          locale
        )}, soc_code_id, soc_title, case_number, worksite_city, worksite_state, employer_business_name, employer_trade_name`,
        filter: FILTER.join(" and "),
        orderby: ORDER_BY.join(","),
        top: TOP,
        skip: SKIP,
        select: SELECT,
        count: true,
        facets: [`job_title${appendLocale(locale)}, count:4, sort:count`],
      }),
    })

    const data = await res.json()

    if (data.error) {
      throw data.error.message
    }

    return { data, azFilter: FILTER, azOrderBy: ORDER_BY, azSearch: search }
  } catch (e) {
    throw new Error(
      `Could not fetch jobs with params: ${JSON.stringify(params)}: ${e}`
    )
  }
}
