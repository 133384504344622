import { appendLocale, isNa } from "utils"
import { getNicePay, getTitleCase, getYesNoFromBoolean } from "./cleaners"
import { getStateAbbreviation } from "./getState"
import parseISO from "date-fns/parseISO"
import { truncate } from "utils/truncate"

const usFormat = (d) => {
  try {
    return new Intl.DateTimeFormat("en-US").format(d)
  } catch (e) {
    console.log(e)
    return d.toString()
  }
}

// Remove timezone information from date before parsing
export const adjustDate = (date) => {
  const dt = date ? date.split("T")[0] : dt
  return parseISO(dt)
}

export function cleanJobPreviewCards({ listing, locale, t }) {
  if (!listing) {
    return null
  }
  const {
    active,
    case_number,
    basic_rate_from,
    basic_rate_to,
    pay_range_desc,
    begin_date,
    end_date,
    employer_trade_name,
    employer_business_name,
    worksite_city,
    worksite_state,
  } = listing

  const dateTimeBegin = adjustDate(begin_date)
  const dateTimeEnd = adjustDate(end_date)

  const employerName = employer_trade_name || employer_business_name
  const worksiteLocation =
    worksite_city && worksite_state
      ? `${worksite_city.trim()}, ${getStateAbbreviation(worksite_state)}`
      : null

  return {
    active,
    case_number,
    preview: {
      case_number,
      title:
        getTitleCase(listing[`job_title${appendLocale(locale)}`]) ||
        getTitleCase(listing["job_title"]) ||
        "N/A",
      beginDate: dateTimeBegin ? usFormat(dateTimeBegin) : "",
      endDate: dateTimeEnd ? usFormat(dateTimeEnd) : "",
      pay: getNicePay({
        from: basic_rate_from,
        to: basic_rate_to,
        per: pay_range_desc,
        t,
      }),
      employerName,
      worksiteLocation,
    },
  }
}

export function cleanListingForMeta({ listing, locale }) {
  if (!listing) {
    return null
  }
  return {
    title: truncate(
      getTitleCase(listing[`job_title${appendLocale(locale)}`]) ||
        getTitleCase(listing["job_title"]) ||
        "N/A",
      71
    ),
    description: truncate(
      listing[`job_duties${appendLocale(locale)}`] || listing["job_duties"],
      150
    ),
    active: listing.active,
  }
}

const getWorksiteAddress = ({
  worksite_address,
  worksite_city,
  worksite_state,
  worksite_zip,
}) => {
  if (!worksite_address) return null
  const address = worksite_address
    .replace(
      `${worksite_city}, ${getStateAbbreviation(
        worksite_state
      )} ${worksite_zip}`,
      ""
    )
    .trim()
  return `${address}, ${worksite_city}, ${getStateAbbreviation(
    worksite_state
  )} ${worksite_zip}`
}

export function cleanListing({ listing, locale, t }) {
  if (!listing) {
    return null
  }
  const {
    case_number,
    job_order_exists,
    active,

    // PREVIEW
    basic_rate_from,
    basic_rate_to,
    pay_range_desc,
    begin_date,
    end_date,
    // job_title (locale dependent)

    // RECRUITMENT INFO
    apply_email,
    apply_phone,
    apply_extension,
    apply_url,

    // JOB DESCRIPTION
    full_time,
    total_positions,
    soc_code_id,
    soc_title,
    // job_duties (locale dependent)

    // JOB REQUIREMENTS
    emp_experience_reqd,
    emp_exp_num_months,
    // special_req (locale dependent)
    work_hour_num_basic,
    hourly_work_schedule_am,
    hourly_work_schedule_pm,

    // EMPLOYER INFORMATION
    employer_business_name,
    employer_trade_name,
    employer_city,
    employer_state,
    employer_zip,
    employer_phone,

    // WORKSITE
    worksite_city,
    worksite_state,
    worksite_zip,
    worksite_address,
    coord,

    // JOB ORDER DETAILS
    worksite_locations,
    // add_wage_info (locale dependent)
    accepted_date,
    ...rest
  } = listing

  const dateTimeBegin = adjustDate(begin_date)
  const dateTimeEnd = adjustDate(end_date)
  const datePosted = adjustDate(accepted_date)

  const translated = listing[`job_title${appendLocale(locale)}`]

  const employerName = employer_trade_name || employer_business_name
  const worksiteLocation =
    worksite_city && worksite_state
      ? `${worksite_city.trim()}, ${getStateAbbreviation(worksite_state)}`
      : null

  return {
    case_number,
    active,
    jobOrderExists: job_order_exists,
    translated,
    preview: {
      title:
        getTitleCase(listing[`job_title${appendLocale(locale)}`]) ||
        getTitleCase(listing["job_title"]) ||
        "N/A",
      beginDate: usFormat(dateTimeBegin),
      endDate: usFormat(dateTimeEnd),
      pay: getNicePay({
        from: basic_rate_from,
        to: basic_rate_to,
        per: pay_range_desc,
        t,
      }),
      employerName,
      worksiteLocation,
    },
    employer: {
      name: employer_trade_name || employer_business_name,
      location:
        employer_city && employer_state
          ? `${employer_city.trim()}, ${getStateAbbreviation(employer_state)}`
          : null,
    },
    worksite: {
      location: worksiteLocation,
      address: getWorksiteAddress({
        worksite_address,
        worksite_city,
        worksite_state,
        worksite_zip,
      }),
      coords: coord ? coord.coordinates : null,
    },
    recruitment: {
      email: apply_email,
      phone: apply_phone,
      extension: apply_extension,
      url: apply_url,
    },
    jobDescription: {
      fullTime: getYesNoFromBoolean({ boolean: full_time, t }),
      numberOfWorkers: total_positions, // number of workers
      jobDuties:
        listing[`job_duties${appendLocale(locale)}`] || listing["job_duties"],
      jobClassification: `${soc_code_id} - ${soc_title}`,
    },
    jobRequirements: {
      experienceRequired: getYesNoFromBoolean({
        boolean: emp_experience_reqd,
        t,
      }),
      monthsRequired: emp_exp_num_months,
      specialRequirements:
        listing[`special_req${appendLocale(locale)}`] || listing["special_req"],
      numberOfHours: work_hour_num_basic,
      workSchedule:
        hourly_work_schedule_am && hourly_work_schedule_pm
          ? `${hourly_work_schedule_am} - ${hourly_work_schedule_pm}`
          : null,
    },
    employerInformation: {
      tradeName: employer_trade_name,
      companyName: employer_business_name,
      location: `${employer_city}, ${getStateAbbreviation(
        employer_state
      )} ${employer_zip}`,
      telephone: employer_phone,
    },
    jobOrderDetails: {
      etaCase: case_number,
      multipleWorksites: getYesNoFromBoolean({
        boolean: worksite_locations,
        t,
      }),
      additionalWage:
        listing[`add_wage_info${appendLocale(locale)}`] ||
        listing["add_wage_info"],
      datePosted: usFormat(datePosted),
    },
    other: {
      ...rest,
    },
  }
}

export function cleanListingForStructuredData({ listing, locale }) {
  if (!listing) {
    return null
  }
  const {
    case_number,
    // job_title,
    // job_duties,
    accepted_date,
    begin_date,
    end_date,
    employer_business_name,
    employer_trade_name,
    employer_city,
    employer_state,
    apply_email,
    apply_phone,
    apply_url,
    employer_email,
    employer_phone,
    worksite_address,
    worksite_city,
    worksite_state,
    worksite_zip,
    basic_rate_from,
    basic_rate_to,
    pay_range_desc,
  } = listing

  return {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    title:
      getTitleCase(listing[`job_title${appendLocale(locale)}`]) ||
      getTitleCase(listing["job_title"]),
    description:
      listing[`job_duties${appendLocale(locale)}`] || listing["job_duties"],
    datePosted: adjustDate(accepted_date),
    jobStartDate: adjustDate(begin_date),
    validThrough: adjustDate(end_date),
    identifier: {
      "@type": "PropertyValue",
      name: "Case Number",
      value: case_number,
    },
    employmentType: "TEMPORARY",
    hiringOrganization: {
      "@type": "Organization",
      name: employer_trade_name || employer_business_name,
      email: isNa(apply_email) ? employer_email : apply_email,
      telephone: isNa(apply_phone) ? employer_phone : apply_phone,
      url: isNa(apply_url) ? "" : apply_url,
      location: `${employer_city}, ${employer_state}`,
    },
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        streetAddress: worksite_address,
        addressLocality: worksite_city,
        addressRegion: worksite_state,
        postalCode: worksite_zip,
        addressCountry: "US",
      },
    },
    baseSalary: {
      "@type": "MonetaryAmount",
      currency: "USD",
      value: {
        "@type": "QuantitativeValue",
        minValue: basic_rate_from,
        maxValue: basic_rate_to,
        unitText: pay_range_desc ? pay_range_desc.toUpperCase() : "",
      },
    },
  }
}
