import { supportedCountryCodes } from "const/countryCodes"
import { AZURE_MAPS_KEY, DATAHUB_SUGGEST } from "env"
import { appendLocale } from "utils"

export async function fetchLocationSuggestions({ search }) {
  try {
    const res = await fetch(
      `https://atlas.microsoft.com/search/address/json?format=json&api-version=1.0&subscription-key=${AZURE_MAPS_KEY}` +
        `&query=${search}&countrySet=${supportedCountryCodes.toString()}&limit=5`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      }
    )
    const data = await res.json()
    if (data.error) {
      console.error(data.error)
      throw data.error.message
    }

    return data.results
  } catch (e) {
    throw new Error(`Could not fetch location suggestions for ${search}, ${e}`)
  }
}

export async function fetchKeywordSuggestions({ key, search, locale }) {
  try {
    const res = await fetch(DATAHUB_SUGGEST, {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "User-Agent": "*",
      },
      body: JSON.stringify({
        search: search || " ",
        top: 12,
        suggesterName: "default",
        searchFields: `job_title${appendLocale(locale)}, soc_code_id`,
        fuzzy: true,
        filter:
          key === "suggestions-search-archive"
            ? `display eq true`
            : `active eq true and display eq true`,
      }),
    })

    const data = await res.json()

    if (data.error) {
      console.error(data.error)
      throw data.error.message
    }

    return data.value
  } catch (e) {
    throw new Error(`Could not fetch suggestions for search: ${e}`)
  }
}

export async function fetchSuggestions(key, search, locale) {
  try {
    if (
      key === "suggestions-search-homepage" ||
      key === "suggestions-search-jobs" ||
      key === "suggestions-search-archive"
    ) {
      return fetchKeywordSuggestions({ key, search, locale })
    } else {
      return fetchLocationSuggestions({ search })
    }
  } catch (e) {
    console.log(e)
    throw new Error(`Could not fetch suggestions for search: ${e}`)
  }
}
